import React from "react"

import { Section, Container } from "../../anti/grid/grid"
import { useScrollAnim } from "src/components/hooks/hooks"

export const PracticeAreasOverview = ({ data }) => {
  const [trigger, anim] = useScrollAnim()
  const block = data
  return (
    <Section forwardRef={trigger} className="practice-areas-overview">
      <Container>
        <div className="row">
          <div
            className={`offset-md-7 col-md-5 offset-xl-8 col-xl-4 offset-xxl-7 col-xxl-5 ${anim(
              2
            )}`}
          >
            <div dangerouslySetInnerHTML={{ __html: block }} />
          </div>
        </div>
      </Container>
    </Section>
  )
}
