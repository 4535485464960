import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"

//Load Components
import { PracticeAreasCover } from "components/shift/practice-areas/cover"
import { PracticeAreasOverview } from "components/shift/practice-areas/overview"
import { PracticeAreasContent } from "components/shift/practice-areas/content"
import { AboutRequest } from "components/shift/about/request"

//Load Context
import { LangContext } from "../context/lang-context"

const PracticeAreasPage = () => {
  const { lang } = useContext(LangContext)
  const data = useStaticQuery(GET_PRACTICE_AREAS_DETAILS)
  const block =
    lang === "EN"
      ? data?.wordPress?.PracticePage?.practiceAreasPage
      : data?.wordPress?.PracticePage?.translation?.practiceAreasPage ||
        data?.wordPress?.PracticePage?.practiceAreasPage
  const request =
    lang === "EN"
      ? data?.wordPress?.aboutPage?.aboutPage
      : data?.wordPress?.aboutPage?.translation?.aboutPage ||
        data?.wordPress?.aboutPage?.aboutPage

  const practiceData = data?.wordPress?.practices?.nodes
  return (
    <Layout>
      <Seo title="Practice Areas" />
      <PracticeAreasCover data={block.cover} />
      <PracticeAreasOverview data={block.overview} />
      <PracticeAreasContent
        data={block.practices}
        list={practiceData}
        lang={lang}
      />
      <AboutRequest data={request.request} />
    </Layout>
  )
}

export default PracticeAreasPage

const GET_PRACTICE_AREAS_DETAILS = graphql`
  query PracticeAreas {
    wordPress {
      PracticePage: page(id: "practice-areas", idType: URI) {
        practiceAreasPage {
          overview
          cover {
            label
            title
            image {
              sourceUrl
            }
          }
          practices {
            title
            text
          }
        }
        translation(language: ID) {
          practiceAreasPage {
            overview
            cover {
              label
              title
              image {
                sourceUrl
              }
            }
            practices {
              title
              text
            }
          }
        }
      }
      aboutPage: page(id: "about", idType: URI) {
        aboutPage {
          request {
            title
            text
            image {
              sourceUrl
            }
            button {
              url
              text
            }
          }
        }
        translation(language: ID) {
          aboutPage {
            request {
              title
              text
              image {
                sourceUrl
              }
              button {
                url
                text
              }
            }
          }
        }
      }
      practices(first: 100, where: { language: EN }) {
        nodes {
          slug
          practice {
            cover {
              title
              image {
                sourceUrl
              }
              thumbnail {
                sourceUrl
              }
            }
            status {
              taxonomy {
                slug
              }
            }
          }
          translation(language: ID) {
            practice {
              cover {
                title
                image {
                  sourceUrl
                }
                thumbnail {
                  sourceUrl
                }
              }
              status {
                taxonomy {
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`
