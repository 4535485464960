import React from "react"

import { Container, Section } from "components/anti/grid/grid"
import { Card } from "components/anti/card/card"
import { Link } from "components/anti/link/link"
import { useScrollAnim } from "src/components/hooks/hooks"

export const PracticeAreasContent = ({ data, list, lang }) => {
  const [trigger, anim] = useScrollAnim()

  const block = data
  return (
    <Section forwardRef={trigger} className="practice-areas-content">
      <Container>
        <div className="heading">
          <h2 className={`${anim(1)}`}>{block.title}</h2>
          <p className={`text-muted ${anim(1)}`}>{block.text}</p>
        </div>
        <div className="row">
          {list.map((data, i) => {
            const dataLang =
              lang === "EN" ? data?.practice : data?.translation?.practice
            const status = dataLang?.status?.taxonomy?.slug
            return (
              <div className="col-sm-6 col-md-4 col-xl-3" key={i}>
                <Card
                  isLink={status === "active" ? true : false}
                  variant="overlay"
                  link={`/practice-areas/${data?.slug}`}
                  img={dataLang?.cover?.thumbnail?.sourceUrl}
                  imgRatio="r-1-1"
                  title={dataLang?.cover?.title}
                  titleClassName="h4"
                  className={`active-hover ${anim(1 + i)}`}
                />
              </div>
            )
          })}
          {/* {list.map((data, i) => {
            const dataLang =
              lang === "EN" ? data?.practice : data?.translation?.practice
            const status = dataLang?.status?.taxonomy?.slug
            if (status === "inactive") {
              return (
                <div className="col-sm-6 col-md-4 col-xl-3" key={i}>
                  <Card
                    variant="overlay"
                    img={dataLang?.cover?.image?.sourceUrl}
                    imgRatio="r-1-1"
                    title={dataLang?.cover?.title}
                    titleClassName="h4"
                    className={`${anim(1)} inactive`}
                  />
                </div>
              )
            }
          })} */}
        </div>
      </Container>
    </Section>
  )
}
